let modalInfos = document.getElementById('modalInfos');
let btnInfos = document.getElementById('infos');
let spanInfos = document.getElementsByClassName('closeInfos')[0];

/// modal partager
let modal = document.getElementById('myModal');
let btn = document.getElementById('partager');
let span = document.getElementsByClassName('close')[0];

const modalEventOpen = (button, modal) => {
  button.onclick = function () {
    modal.style.display = 'block';
  };
};

const modalEventClose = (button, modal) => {
  button.onclick = function () {
    modal.style.display = 'none';
  };
};

btnInfos && modalEventOpen(btnInfos, modalInfos);
spanInfos && modalEventClose(spanInfos, modalInfos);

btn && modalEventOpen(btn, modal);
span && modalEventClose(span, modal);

// When the user clicks anywhere outside of the modal, close it
window.onclick = (event) => {
  if (event.target == modal) {
    modal.style.display = 'none';
  } else if (event.target == modalInfos) {
    modalInfos.style.display = 'none';
  }
};

/****************************************************************
 * Share
 */
let copyBtn = document.getElementById('copy');

if (copyBtn) {
  copyBtn.addEventListener('click', (el) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText('https://www.lebonplein.fr');
      alert("Vous pouvez maintenant partager l'adresse du site");
    } else {
      alert(
        "Votre navigateur n'est pas compatible avec cette fonctionnalité..."
      );
    }
  });
}
